$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
/* === Import Bootstrap functions and variables === */

@import "@oimmei/pololight/bootstrap/scss/functions";
@import "@oimmei/pololight/bootstrap/scss/variables";
/*-------------------------------------------------------------------*/

/* === Import template variables === */

@import "variables";
@import "../../../../../assets/scss/poloVariables";
/*-------------------------------------------------------------------*/

/* === Bootstrap Main SCSS === */

@import "@oimmei/pololight/bootstrap/scss/bootstrap";
/*-------------------------------------------------------------------*/
